import { FC } from 'react'
import { WarningImage } from '../assets'
import { WantedVehicleV2 } from '../models'
import WantedItemCard from './WantedItemCard'

type Props = {
  data: WantedVehicleV2[]
  className?: string
}

const Wanted: FC<Props> = ({ data, className }) => {
  return (
    <div className={className} style={ { position: 'relative' } }>
      <img className='left-icon-absolute' alt='warning' src={WarningImage} />
      <WantedItemCard wanted={data[0]} />
    </div>
  )
}

export default Wanted
