import { UiText } from '@ory/kratos-client'

type MessagesProps = {
  messages?: Array<UiText>
}

export const Messages = ({ messages }: MessagesProps) => {
  if (!messages) {
    // No messages? Do nothing.
    return null
  }

  if (messages.length === 0) {
    return null
  }

  return (
    <div>
      {
        messages.map((message) => {
          switch (message.type) {
            case 'error':
              return <div key={message.id} className='notification notification-error'>{message.text}</div>
            default:
              return <div key={message.id} className='notification notification-info'>{message.text}</div>
          }
        })
      }
    </div>
  )
}
