import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import App from './App'

import './reset.css'
import './index.css'
import './forms.css'
import './navigation_bar.css'
import ReactGA from 'react-ga4'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement!)

ReactGA.initialize('G-XNMB6JY6W8')

root.render(
  <StrictMode>
    <App />
  </StrictMode>
)
