import { FC, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import Modal from 'react-modal'

import CopyToClipboard from '../CopyToClipboard/CopyToClipboard'

import styles from './CreateTokenModal.module.css'

import { CloseImage } from '../../assets'
import { useAddTokenMutation } from '../../store/tokens_api'
import { Token } from '../../models'
import { CreateTokenModalProps } from './CreateTokenModal.props'

interface FormValues {
  name: string;
}

const CreateTokenModal: FC<CreateTokenModalProps> = ({ open, onClose }) => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm<FormValues>()

  const [newToken, setNewToken] = useState<Token| undefined>(undefined)
  const [addToken] = useAddTokenMutation()

  const onSubmit: SubmitHandler<FormValues> = (reqData) => {
    addToken(reqData).unwrap().then((payload) => {
      setNewToken(payload)
      reset()
    })
  }

  const onCloseInternal = () => {
    setNewToken(undefined)
    reset()
    onClose()
  }

  return (
    <Modal overlayClassName={styles.overlay} className={styles.content} isOpen={open} onRequestClose={onCloseInternal}>
      <button className='left-icon-absolute' onClick={onCloseInternal}>
        <img alt='close' src={CloseImage} />
      </button>

      {
        newToken &&
        <div>
          <strong className={styles.title}>“{newToken.name}”</strong>
          <CopyToClipboard textClassName={styles.title} text={newToken.secret!} />
          <span className={styles.note}>Обов’язково скопіюйте ваш токен, адже його більше не буде показано</span>
        </div>
      }

      {
        !newToken && <div>
            <h2>Генерація нового токена</h2>
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>

              <div className='form-control'>
                <label htmlFor='name'>Назва</label>
                <input type='text' className={errors.name ? 'input-error' : 'input-success'}
                  {
                  ...register('name', {
                    required: true,
                    pattern: {
                      value: /^[a-zA-Z0-9-_]*$/,
                      message: 'Некоректна назва'
                    }
                  })
                  }
                />
                {
                  errors.name && <div className='error'>{errors.name.message}</div>
                }
              </div>

              <button className={['button', 'primary-button'].join(' ')}>
                Згенерувати
              </button>
            </form>
          </div>
      }
    </Modal>
  )
}

export default CreateTokenModal
