import { configureStore } from '@reduxjs/toolkit'
import BaseAPI from './api'

export const store = configureStore({
  reducer: {
    [BaseAPI.reducerPath]: BaseAPI.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    BaseAPI.middleware
  )
})
