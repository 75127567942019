import { Token, TokenList } from '../models'
import BaseAPI from './api'

const TokenAPI = BaseAPI.injectEndpoints({
  endpoints: (build) => ({
    getTokens: build.query<TokenList, number>({
      query: (count) => `${process.env.REACT_APP_OPENCARS_PUBLIC_URL}/api/v1/user/tokens?limit=${count}`,
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Tokens' as const, id })), { type: 'Tokens', id: 'LIST' }]
          : [{ type: 'Tokens', id: 'LIST' }]
    }),
    addToken: build.mutation<Token, any>({
      query: (body) => ({
        url: '/api/v1/user/tokens',
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'Tokens', id: 'LIST' }]
    }),
    deleteToken: build.mutation<Token, string>({
      query: (id) => ({
        url: `/api/v1/user/tokens/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'Tokens', id: 'LIST' }]
    }),
    resetToken: build.mutation<Token, string>({
      query: (id) => ({
        url: `/api/v1/user/tokens/${id}/reset`,
        method: 'POST'
      })
    })
  })
})

export const { useGetTokensQuery, useAddTokenMutation, useDeleteTokenMutation, useResetTokenMutation } = TokenAPI
