import { createContext, FC, ReactNode, useEffect, useState } from 'react'
import { Session } from '@ory/kratos-client'

import { KratosAPI } from '../api'

type SessionContext = {
  session?: Session,
  logoutToken?: string
  isAuthenticated: boolean,
} | undefined | null

type ExternalSessionContext = {
  session?: Session,
  logoutToken?: string
  isAuthenticated: boolean,
  setSession: (session: SessionContext) => void
}

export const AuthContext = createContext<ExternalSessionContext>({
  isAuthenticated: false,
  setSession: () => {}
})

type AuthProviderProps = {
  children: ReactNode
}

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [sessionContext, setSessionContext] = useState<SessionContext>(undefined)

  useEffect(() => {
    const checkSession = () => {
      KratosAPI.toSession().then((resp) => {
        return KratosAPI.createSelfServiceLogoutFlowUrlForBrowsers().then(({ data }) => {
          setSessionContext(() => ({ session: resp.data, isAuthenticated: true, logoutToken: data.logout_token }))
        })
      }).catch(() => {
        setSessionContext(null)
      })
    }

    checkSession()
  }, [setSessionContext])

  const setAuth = (session: SessionContext) => {
    setSessionContext(session)
  }

  if (sessionContext === undefined) {
    return null
  }

  return (
    <AuthContext.Provider value={{
      session: sessionContext?.session,
      logoutToken: sessionContext?.logoutToken,
      isAuthenticated: Boolean(sessionContext?.isAuthenticated),
      setSession: setAuth
    }
    }>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
