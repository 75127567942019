import { getNodeLabel, NodeInputProps } from './helpers'

export function NodeInputSubmit({
  node,
  attributes,
  setValue,
  disabled,
  dispatchSubmit
}: NodeInputProps) {
  const onClick = () => {
    // This section is only used for WebAuthn. The script is loaded via a <script> node
    // and the functions are available on the global window level. Unfortunately, there
    // is currently no better way than executing eval / function here at this moment.
    if (attributes.onclick) {
      // eslint-disable-next-line no-new-func
      const run = new Function(attributes.onclick)
      run()
    }
  }

  const classNames = ['button']
  if (attributes.value === 'google') {
    classNames.push('submit-google-button')
  } else {
    classNames.push('primary-button')
  }

  const getLabel = () => {
    switch (attributes.value) {
      case 'google':
        return (
          <div className='with-logo-container'>
            <svg className={'logo'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.9002 8.1759C14.9008 7.65065 14.8573 7.12631 14.7702 6.60864H7.6499V9.57723H11.7281C11.6446 10.0514 11.468 10.5033 11.2088 10.9059C10.9497 11.3084 10.6133 11.6533 10.2201 11.9196V13.8465H12.654C14.0791 12.5051 14.9002 10.5214 14.9002 8.1759Z" fill="#3081F6" />
              <path d="M7.6497 15.7082C9.68721 15.7082 11.4028 15.0252 12.6538 13.8476L10.2199 11.9207C9.54249 12.3896 8.67003 12.6572 7.6497 12.6572C5.68036 12.6572 4.00883 11.3019 3.4111 9.47559H0.903809V11.4614C1.53222 12.738 2.49583 13.8112 3.68704 14.5611C4.87826 15.311 6.2502 15.7082 7.6497 15.7082Z" fill="#3081F6" />
              <path d="M3.41111 9.47553C3.09512 8.51852 3.09512 7.48215 3.41111 6.52514V4.53931H0.903816C0.375171 5.61322 0.0998535 6.79845 0.0998535 8.00034C0.0998535 9.20223 0.375171 10.3875 0.903816 11.4614L3.41111 9.47553Z" fill="#3081F6" />
              <path d="M7.6497 3.34351C8.72643 3.32556 9.76684 3.74087 10.546 4.49969L12.701 2.29975C11.3345 0.989496 9.52427 0.270146 7.6497 0.292498C6.2502 0.292563 4.87826 0.689724 3.68704 1.43964C2.49583 2.18956 1.53222 3.26271 0.903809 4.5393L3.4111 6.52514C4.00883 4.69881 5.68036 3.34351 7.6497 3.34351Z" fill="#3081F6" />
            </svg>
            <div className='logo-text'>{getNodeLabel(node)}</div>
          </div>
        )
      default:
        return getNodeLabel(node)
    }
  }

  return (
    <button className={classNames.join(' ')}
      name={attributes.name}
      onClick={(e: any) => {
        onClick()
        setValue(attributes.value).then(() => dispatchSubmit(e))
      }}
      value={attributes.value || ''}
      disabled={attributes.disabled || disabled}>
      {getLabel()}
    </button>
  )
}
