import { FC } from 'react'
import { PackageCardProps } from './PackageCard.props'

import styles from './PackageCard.module.css'

const PackageCard: FC<PackageCardProps> = ({ request_count, price_amount, price_currency_code }) => {
  return (
    <div className={styles.container}>
      <div className={styles.padding}>
        <div className={styles.row}>
          <strong>Кількість запитів</strong>
          <div>{request_count}</div>
        </div>

        <div className={styles.row}>
          <strong>Ціна</strong>
          <div>{price_amount} {price_currency_code}</div>
        </div>

        <button className='button primary-button'>
          Купити
        </button>
      </div>
    </div>
  )
}

export default PackageCard
