import { useContext } from 'react'

import { AuthContext, PackageCard } from '../components'
import { useGetPackagesQuery } from '../store/package_api'

const ApiOverviewPage = () => {
  const { isAuthenticated } = useContext(AuthContext)

  if (!isAuthenticated) {
    return <div>Not Authenticated</div>
  }

  const { data = { data: [] }, error, isLoading } = useGetPackagesQuery(100)

  if (isLoading) {
    return <h1>Идет загрузка...</h1>
  }

  if (error && 'data' in error) {
    return <div>{error.status} {JSON.stringify(error.data)}</div>
  }

  return (
    <div className='list-of-packages'>
      <div className='packages-heading'>
        <h1>Пакети</h1>

        <div className='packages-wrapper'>
          <div className='packages-list'>
            {
              data.data.map((item) => {
                return item.prices.map((price) =>
                  <PackageCard key={`${item.id}-${price.amount}`}
                    request_count={item.request_count}
                    price_amount={price.amount}
                    price_currency_code={price.currency_code}
                  />
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApiOverviewPage
