import { FC } from 'react'
import { NotFoundImage } from '../../assets'

import styles from './NotFound.module.css'

const NotFound: FC = () => {
  return (
    <div className={styles.container}>
      <img alt='Не знайдено' src={NotFoundImage}/>
      <h2>Інформація відсутня</h2>
      <h3>Перевірьте коректність введених данних</h3>
    </div>
  )
}

export default NotFound
