import React from 'react'
import { Helmet } from 'react-helmet'

const ContactsPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Контакти</title>
      </Helmet>

      <h1>Контакти</h1>
    </React.Fragment>
  )
}

export default ContactsPage
