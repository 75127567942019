import { FC } from 'react'

import InfoBarItemProps from './InfoBarItem.props'
import styles from './InfoBarItem.module.css'

const InfoBarItem: FC<InfoBarItemProps> = ({ image, title, value }) => {
  if (!value) {
    return null
  }

  return (
    <div className={styles.root}>
      <div>
        <img className={styles.image} alt={title.toString()} src={image} />
      </div>
      <div>
        <div className={styles.title}>{title}</div>
        <div className={styles.value}>{value}</div>
      </div>
    </div>
  )
}

export default InfoBarItem
