import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Chips from '../Chips/Chips'

import styles from './SearchBox.module.css'
import { SearchBoxProps } from './SearchBox.props'

const SearchBox: FC<SearchBoxProps> = ({ chips }) => {
  const [query, setQuery] = useState<string>('')
  const [chipIndex, setChipIndex] = useState<number>(0)

  const navigate = useNavigate()

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setQuery(e.target.value.toUpperCase())
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      navigate(chips[chipIndex].path + query)
    }
  }

  const onActionClick = () => {
    if (query !== '') {
      navigate(chips[chipIndex].path + query)
    }
  }

  const isFilled = () => query.length >= chips[chipIndex].length

  return (
    <div className={styles.wrapper}>
      <div className={styles.centered}>
        <div className={styles.chips}>
            <Chips setSelectedIndex={setChipIndex} elements={chips} chipClassName={styles.chip} selectedChipClassName={styles.selectedChip}/>
        </div>

        <div className={styles.inputBox}>
          <input type='text' autoComplete='off' className={styles.input} placeholder={chips[chipIndex].placeholder} value={query}
            onChange={onChange}
            onKeyDown={isFilled() ? onKeyDown : undefined}
          />

          <button className={styles.searchButton} disabled={query.length < chips[chipIndex].length} onClick={onActionClick}>
            {
              isFilled()
                ? <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'>
                  <path fill='#3081F6' d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z' />
                </svg>
                : <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'>
                  <path fill='#63708A' d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z' />
                </svg>
            }
          </button>
        </div>
      </div>
    </div>
  )
}

export default SearchBox
