import { getNodeLabel, NodeInputProps } from './helpers'

export function NodeInputCheckbox({ node, attributes, setValue, disabled }: NodeInputProps) {
  return (
    <>
      <label htmlFor={attributes.name}>{getNodeLabel(node)}</label>
      <input type="checkbox"
        id={attributes.name}
        name={attributes.name}
        defaultChecked={attributes.value === true}
        onChange={(e: any) => setValue(e.target.checked)}
        disabled={attributes.disabled || disabled}
      />
    </>
  )
}
