import { FC } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import NavigationBar from './NavigationBar/NavigationBar'

import { HomePage, NotFoundPage, SignInPage, SignUpPage, RecoveryPage, ProfilePage, SettingsPage, ErrorPage, ApiOverviewPage, ContactsPage, ReportByNumberPage, ReportByVinPage } from '../pages'

const Navigation: FC = () => {
  const navBarItems = [
    {
      title: 'Головна',
      to: '/'
    }
  ]

  return (
    <BrowserRouter>
        <NavigationBar items={navBarItems} />

        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/profile' element={<ProfilePage />} />
          <Route path='/login' element={<SignInPage />} />
          <Route path='/registration' element={<SignUpPage />} />
          <Route path='/settings' element={<SettingsPage />} />
          <Route path='/recovery' element={<RecoveryPage />} />
          <Route path='/chd ' element={<ApiOverviewPage />} />
          <Route path='/error' element={<ErrorPage />} />
          <Route path='/contacts' element={<ContactsPage />} />
          <Route path='/number/:number' element={<ReportByNumberPage />} />
          <Route path='/vin/:vin' element={<ReportByVinPage />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
    </BrowserRouter>
  )
}

export default Navigation
