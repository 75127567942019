import { NodeInputButton } from './NodeInputButton'
import { NodeInputCheckbox } from './NodeInputCheckbox'
import { NodeInputDefault } from './NodeInputDefault'
import { NodeInputHidden } from './NodeInputHidden'
import { NodeInputSubmit } from './NodeInputSubmit'
import { NodeInputPassword } from './NodeInputPassword'
import { NodeInputProps } from './helpers'

export function NodeInput(props: NodeInputProps) {
  const { attributes } = props

  switch (attributes.type) {
    case 'hidden':
      return <NodeInputHidden {...props} />
    case 'checkbox':
      // Render a checkbox. We have one hidden element which is the real value (true/false), and one
      // display element which is the toggle value (true)!
      return <NodeInputCheckbox {...props} />
    case 'button':
      return <NodeInputButton {...props} />
    case 'submit':
      return <NodeInputSubmit {...props} />
    case 'password':
      return <NodeInputPassword {...props} />
  }

  // Render a generic text input field.
  return <NodeInputDefault {...props} />
}
