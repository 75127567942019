import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import { Helmet } from 'react-helmet'

import { useGetReportByNumberQuery } from '../../store/report_api'
import { DecodedVIN, NotFound, SaleInfo, Actions, Wanted } from '../../components'
import { reportTitle } from '../../models'

import styles from './ReportByNumberPage.module.css'

type ParamTypes = {
  number: string
}

const ReportByNumberPage = () => {
  const { number } = useParams<ParamTypes>()

  const [idx, setIdx] = useState(0)

  const { data, isLoading } = useGetReportByNumberQuery(number!)

  if (isLoading) {
    return null
  }

  if (!data || !data.vehicles) {
    return <NotFound />
  }

  return (
    <>
      <Helmet>
        <title>{reportTitle(data)}</title>
        <meta name='description' content='Проста та зрозуміла перевірка авто за номером або він-кодом' />
        <meta name='theme-color' content='#000000' />
      </Helmet>

      <Tabs selectedIndex={idx} selectedTabClassName={styles.tabSelected} onSelect={(index: number) => { setIdx(index) }}>
        <TabList className={styles.tabList}>
          {
            data.vehicles.map((item, idx) => {
              return <Tab className={styles.tab} key={idx}>{item.brand} {item.model} {item.year}</Tab>
            })
          }
        </TabList>

        {
          data.vehicles.map((vehicle, idx) => {
            return (
              <TabPanel className={styles.tabPanel} key={idx}>
                <Actions className={styles.container} data={vehicle.actions} />
                {
                  vehicle.vin?.decoded_vin &&
                  <DecodedVIN className={styles.container} vin={vehicle.vin}/>
                }
                {
                  vehicle.advertisements &&
                  <SaleInfo className={styles.container} data={vehicle.advertisements} />
                }
                {
                  vehicle.wanted &&
                  <Wanted className={styles.wantedContainer} data={vehicle.wanted} />
                }
              </TabPanel>
            )
          })
        }
      </Tabs>
    </>
  )
}

export default ReportByNumberPage
