import { useEffect, useContext } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import { SignUpForm, AuthContext } from '../components'

const SignUpPage = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const { isAuthenticated } = useContext(AuthContext)

  useEffect(() => {
    const checkSession = () => {
      if (isAuthenticated) {
        navigate('/profile')
      }
    }

    checkSession()
  }, [isAuthenticated])

  return (
    <div className='container'>
      <div className='right-view'>
        <h1>Зареєструватися</h1>
        <div className='subheading'>
          <span>Ви вже маєте обліковий запис?</span>
          <Link className='link' to='/login'>Увійти</Link>
        </div>
        <SignUpForm flowId={searchParams.get('flow')} />
      </div>
    </div>
  )
}

export default SignUpPage
