import { upperFirst, lowerCase } from 'lodash'

import { ColorImage, GasImage, WeightImage, TypeImage, SeatImage, BodyImage } from '../../assets'

import { Row, InfoBarItem } from '..'

import styles from './ActionCard.module.css'
import { ActionCardProps } from './ActionCard.props'
import { FC } from 'react'
import { Owner, toLocalDate } from '../../models'

const ActionCard: FC<ActionCardProps> = ({ action }) => {
  const formatOwner = (owner: Owner) => {
    switch (owner.entity) {
      case 'INDIVIDUAL':
        return 'Фізична особа'
      case 'LEGAL':
        return 'Юридична особа'
    }
  }

  return (
    <div>
      <div className={styles.infoBar}>
        {
          action.color && <InfoBarItem title='Колір' value={upperFirst(lowerCase(action.color))} image={ColorImage} />
        }
        {
          action.fuel && <InfoBarItem title='Пальне' value={upperFirst(lowerCase(action.fuel))} image={GasImage} />
        }
        {
          action.own_weight && <InfoBarItem title='Маса' value={action.own_weight + ' / ' + action.total_weight} image={WeightImage} />
        }
         {
          action.capacity && <InfoBarItem title="Об'єм двигуна" value={action.capacity} image={GasImage} />
        }
        {
          action.body && <InfoBarItem title='Кузов' value={upperFirst(lowerCase(action.body.split('-')[0]))} image={BodyImage} />
        }
        {
          action.kind && <InfoBarItem title='Тип' value={upperFirst(lowerCase(action.kind))} image={TypeImage} />
        }
        {
          action.num_seating && <InfoBarItem title='Місця' value={action.num_seating} image={SeatImage} />
        }
      </div>

      <div>
        <Row title='Номер'>{action.number}</Row>
        <Row title='Департамент'>{action.department?.name || 'Невідомо'}</Row>
        <Row title='Призначення'>{action.purpose ? upperFirst(lowerCase(action.purpose)) : 'Невідомо'}</Row>
        <Row title='Опис'>{action.action ? upperFirst(lowerCase(action.action.title)) : 'Невідомо'}</Row>
        <Row title='Власник'>{action.owner ? formatOwner(action.owner) : 'Невідомо'}</Row>
        <Row title='Адреса власника'>{action.owner?.registration?.summary || 'Невідомо'}</Row>
        {/* WE NEED TO THINK HOW TO SHOW ACTION PROPERLY */}
        {
          action.first_reg_date && <Row title='Дата першої реєстрації'>{toLocalDate(action.first_reg_date)}</Row>
        }
        {
          action.vin && <Row title='VIN'>{action.vin}</Row>
        }
      </div>
    </div>
  )
}

export default ActionCard
