import { FC, useState } from 'react'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { toLocalDate } from '../../models'

import styles from './Actions.module.css'
import { ActionsProps } from './Actions.props'
import ActionCard from '../ActionCard/ActionCard'

const Actions: FC<ActionsProps> = ({ data, className }) => {
  const [idx, setIdx] = useState(0)

  if (!data || data.length === 0) {
    return null
  }

  return (
    <div className={className}>
      <Tabs selectedIndex={idx} selectedTabClassName={styles.tabSelected} onSelect={(index: number) => { setIdx(index) }}>
        <TabList className={styles.tabList}>
          {
            data.map((item, idx) => <Tab className={styles.tab} key={idx}>{toLocalDate(item.date)}</Tab>)
          }
        </TabList>

        {
          data.map((action, idx) => {
            return (
              <TabPanel className={styles.tabPanel} key={idx}>
                <ActionCard key={idx} action={action} />
              </TabPanel>
            )
          })
        }
      </Tabs>
    </div>
  )
}

export default Actions
