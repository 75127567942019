import { SelfServiceError } from '@ory/kratos-client'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { KratosAPI } from '../api'

const ErrorPage = () => {
  const [searchParams] = useSearchParams()
  const [error, setError] = useState<SelfServiceError>()

  const id = searchParams.get('id')

  const navigate = useNavigate()

  const styles = {
    container: {
      padding: 24
    }
  }

  useEffect(() => {
    if (id) {
      KratosAPI.getSelfServiceError(id).then(({ data }) => {
        setError(data)
      }).catch((err: AxiosError) => {
        switch (err.response?.status) {
          case 404:
            // The error id could not be found. Let's just redirect home!
            break
          case 403:
            // The error id could not be fetched due to e.g. a CSRF issue. Let's just redirect home!
            break
          case 410:
            // The error id expired. Let's just redirect home!
            return navigate('login')
        }

        return Promise.reject(err)
      })
    }
  }, [id, setError])

  if (!error) {
    return null
  }

  return (
    <div style={styles.container}>
      {JSON.stringify(error, null, 2)}
    </div>
  )
}

export default ErrorPage
