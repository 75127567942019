export type CommonDate = {
  year: number;
  month: number;
  day: number;
}

export type Department = {
  code: number;
  name: string;
}

export const toLocalDate = (date: CommonDate) => {
  const result = new Date(date.year, date.month - 1, date.day, 0, 0, 0)

  const options = {
    year: 'numeric' as const,
    month: 'long' as const,
    day: 'numeric' as const
  }

  return result.toLocaleDateString('uk-UA', options).slice(0, -2)
}

export const toDateFromStr = (date: string) => {
  const result = new Date(date)

  const options = {
    year: 'numeric' as const,
    month: 'long' as const,
    day: 'numeric' as const
  }

  return result.toLocaleDateString('uk-UA', options).slice(0, -2)
}

export const toDateFromISO8601 = (date: string) => {
  const result = new Date(date)

  const options = {
    year: 'numeric' as const,
    month: 'long' as const,
    day: 'numeric' as const
  }

  return result.toLocaleDateString('uk-UA', options).slice(0, -2)
}
