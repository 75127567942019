import { FC, useState } from 'react'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { BodyType, FuelType, GearboxType, WheelDriveType, Category, toDateFromISO8601 } from '../../models'
import { InfoBarItem, Row } from '../../components'

import styles from './SaleInfo.module.css'
import { SaleInfoProps } from './SaleInfo.props'
import { BodyImage, GasImage, TypeImage } from '../../assets'

const SaleInfo: FC<SaleInfoProps> = ({ data, className }) => {
  const [idx, setIdx] = useState(0)

  if (!data || data.length === 0) {
    return null
  }

  const fuelUA = (x: FuelType) => {
    switch (x) {
      case 'FUEL_GASOLINE':
        return 'Бензин'
      case 'FUEL_DIESEL':
        return 'Дизель'
      case 'FUEL_GAS':
        return 'Газ'
      case 'FUEL_GAS_GASOLINE':
        return 'Бензин та Газ'
      case 'FUEL_HYBRID':
        return 'Гібрид'
      case 'FUEL_ELECTRIC':
        return 'Електро'
      case 'FUEL_PROPANE':
        return 'Пропан'
      case 'FUEL_METHANE':
        return 'Метан'
      case 'FUEL_OTHER':
        return 'Інше'
      case 'FUEL_UNKNOWN':
        return 'Невідомо'
    }
  }

  const gearboxUA = (x: GearboxType) => {
    switch (x) {
      case 'GEARBOX_AUTOMATIC':
        return 'Автоматична'
      case 'GEARBOX_MANUAL':
        return 'Механічна'
      case 'GEARBOX_MANUMATIC':
        return 'Manumatic'
      case 'GEARBOX_VARIATOR':
        return 'Варіатор'
      case 'GEARBOX_AMT':
        return 'AMT'
      case 'GEARBOX_OTHER':
        return 'Інше'
      case 'GEARBOX_UKNOWN':
        return 'Невідомо'
    }
  }

  const wheelDriveUA = (x: WheelDriveType) => {
    switch (x) {
      case 'WHEELDRIVE_FRONT':
        return 'Передній'
      case 'WHEELDRIVE_REAR':
        return 'Задній'
      case 'WHEELDRIVE_ALL':
        return 'Повний'
      case 'WHEELDRIVE_UNKNOWN':
        return 'Невідомо'
    }
  }

  const bodyUA = (x: BodyType) => {
    switch (x) {
      case 'BODY_SEDAN':
        return 'Седан'
      case 'BODY_CROSSOVER':
        return 'Кросовер'
      case 'BODY_MINIVAN':
        return 'Мінівен'
      case 'BODY_HATCHBACK':
        return 'Хетчбек'
      case 'BODY_WAGON':
        return 'WAGON'
      case 'BODY_COUPE':
        return 'Купе'
      case 'BODY_CONVERTIBLE':
        return 'Кабріолет'
      case 'BODY_PICKUP':
        return 'Пікап'
      case 'BODY_LIMOUSINE':
        return 'Лімузин'
      case 'BODY_LIGHTTRUCK':
        return 'Легка Вантажівка'
      case 'BODY_OTHER':
        return 'Інше'
    }
  }

  const categoryUA = (x: Category) => {
    switch (x) {
      case 'CATEGORY_CAR':
        return 'Легковий'
      case 'CATEGORY_TRUCK':
        return 'Вантажівка'
      case 'CATEGORY_MOTO':
        return 'Мототранспорт'
      case 'CATEGORY_BUS':
        return 'Автобус'
      case 'CATEGORY_WATER':
        return 'Водний транспорт'
      case 'CATEGORY_AIR':
        return 'Повітряний Транспорт'
      case 'CATEGORY_CAMPER':
        return 'Автодом'
      case 'CATEGORY_TRAILER':
        return 'Причіп'
      case 'CATEGORY_SPECIAL':
        return 'Спеціалізований'
      case 'CATEGORY_UNKNOWN':
        return 'Невідомо'
    }
  }

  return (
    <div className={className}>
      <div className={styles.heading}>Було у продажі</div>
      <Tabs selectedIndex={idx} selectedTabClassName={styles.tabSelected} onSelect={(index: number) => { setIdx(index) }}>
        <TabList className={styles.tabList}>
          {
            data.map((item, idx) => <Tab className={styles.tab} key={idx}>{toDateFromISO8601(item.published_at)}</Tab>)
          }
        </TabList>

        {
          data.map((item, idx) => {
            return (
              <TabPanel className={styles.tabPanel} key={idx}>
                <div className={styles.infoBar}>
                  <InfoBarItem title='Пальне' value={fuelUA(item.fuel)} image={GasImage}/>
                  <InfoBarItem title='Кузов' value={bodyUA(item.body)} image={BodyImage}/>
                  <InfoBarItem title='Привід' value={wheelDriveUA(item.wheel_drive)} image={TypeImage}/>
                  {
                    item.engine_capacity &&
                    <InfoBarItem title="Об'єм двигуна" value={(item.engine_capacity * 1000) } image={GasImage}/>
                  }
                  {
                    item.category &&
                    <InfoBarItem title='Тип' value={categoryUA(item.category)} image={TypeImage} />
                  }
                </div>

                <div>
                  <Row title='Модель'>{item.brand} {item.model} {item.year}</Row>
                  <Row title='Вартість'>{item.price}$</Row>
                  <Row title='Коробка передач'>{gearboxUA(item.gearbox)}</Row>
                  <Row title='Одометр (км)'>{item.mileage}</Row>
                  { item.number && <Row title='Номер'>{item.number}</Row> }
                  <Row title='Дата видалення'>{toDateFromISO8601(item.last_seen_at)}</Row>
                </div>

                {/* {
                  item.image_urls.map((url, i) => {
                    return <img style={{ height: '300px' }} key={i} src={url}></img>
                  })
                } */}
              </TabPanel>
            )
          })
        }
      </Tabs>
    </div>
  )
}

export default SaleInfo
