// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// Define a service using a base URL and expected endpoints
const BaseAPI = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    prepareHeaders: (headers) => {
      headers.set('X-Api-Key', '2TqZb9A4YPMmw2XvVWTB2b2RPE5IiI6l')
      return headers
    }
  }),
  tagTypes: ['Package', 'Tokens', 'WantedVehicle'],
  endpoints: () => ({})
})

export default BaseAPI
