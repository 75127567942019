import axios from 'axios'

import { Configuration, V0alpha2Api } from '@ory/kratos-client'

const API = axios.create(
  {
    baseURL: process.env.REACT_APP_OPENCARS_PUBLIC_URL,
    headers: {
      'Api-Key': '2TqZb9A4YPMmw2XvVWTB2b2RPE5IiI6l'
    },
    withCredentials: true
  }
)

const AuthAPI = axios.create(
  {
    baseURL: process.env.REACT_APP_OPENCARS_PUBLIC_URL,
    withCredentials: true
  }
)

const config = new Configuration({
  basePath: process.env.REACT_APP_KRATOS_PUBLIC_URL,
  baseOptions: {
    withCredentials: true
  }
})

const KratosAPI = new V0alpha2Api(config)

export { API, AuthAPI, KratosAPI }
