import styles from './LinearProgress.module.css'

const LinearProgress = () => {
  return (
    <div className={styles.progressBar}>
      <div className={styles.progressBarValueA}></div>
    </div>
  )
}

export default LinearProgress
