import { FC, useState } from 'react'
import { ChipsProps } from './Chips.props'

const Chips: FC<ChipsProps> = ({ elements, chipClassName, setSelectedIndex, selectedChipClassName }) => {
  const [chipIndex, setChipIndex] = useState<number>(0)

  const onSelect = (index: number) => () => {
    setChipIndex(index)
    if (setSelectedIndex) setSelectedIndex(index)
  }

  const className = (index: number) => {
    if (selectedChipClassName && index === chipIndex) {
      return selectedChipClassName
    }

    if (chipClassName) {
      return chipClassName
    }

    return ''
  }

  return (
    <>
      {
        elements.map((chip, index) => {
          return (
            <button disabled={chip.disabled} key={index} className={className(index)} onClick={onSelect(index)}>
              {chip.label}
            </button>
          )
        })
      }
    </>
  )
}

export default Chips
