import { ListResponse, Package } from '../models'
import BaseAPI from './api'

const PackageAPI = BaseAPI.injectEndpoints({
  endpoints: (build) => ({
    getPackages: build.query<ListResponse<Package>, number>({
      query: (count) => `${process.env.REACT_APP_OPENCARS_BILLING_API_URL}/api/v1/billing/packages?limit=${count}`,
      providesTags: (result) =>
        result
          ? [...result.data.map(({ id }) => ({ type: 'Package' as const, id })), { type: 'Package', id: 'LIST' }]
          : [{ type: 'Package', id: 'LIST' }]
    })
  })
})

export const { useGetPackagesQuery } = PackageAPI
