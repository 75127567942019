import { useState, useEffect, FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { SubmitSelfServiceRegistrationFlowBody, SelfServiceRegistrationFlow, UiNode } from '@ory/kratos-client'
import { AxiosError } from 'axios'

import { Flow } from '../ui/Flow'
import { KratosAPI } from '../api'
import { handleFlowError } from '../ui/errors'

type SignUpFormProps = {
  flowId?: string | null,
}

const SignUpForm: FC<SignUpFormProps> = ({ flowId }) => {
  const [flow, setFlow] = useState<SelfServiceRegistrationFlow>()

  const navigate = useNavigate()

  useEffect(() => {
    if (!flowId) {
      KratosAPI.initializeSelfServiceRegistrationFlowForBrowsers().then(({ data }) => setFlow(data))
    } else {
      KratosAPI.getSelfServiceRegistrationFlow(flowId).then(({ data }) => setFlow(data))
    }
  }, [setFlow])

  const onSubmit = (values: SubmitSelfServiceRegistrationFlowBody) => {
    return KratosAPI.submitSelfServiceRegistrationFlow(String(flow?.id), values).then(() => {
      navigate('login')
    }).catch(
      handleFlowError(navigate, 'registration', setFlow)
    ).catch((err: AxiosError) => {
      if (err.response?.status === 400) {
        setFlow(err.response.data as SelfServiceRegistrationFlow)
      }

      return Promise.reject(err)
    })
  }

  const sortFn = (a: UiNode, b: UiNode) => {
    if (b.group === 'oidc') { return -1 }

    return 0
  }

  const rewriteNode = (node: UiNode) => {
    if (!node.meta.label) {
      return node
    }

    switch (node.meta.label?.id) {
      case 1070004:
        node.meta.label.text = 'Email'
        break
      case 1070001:
        node.meta.label.text = 'Пароль'
        break
      case 1040001:
        node.meta.label.text = 'Зареєструватися'
        break
      case 1040002:
        node.meta.label.text = 'Зареєструватися через Google'
        break
    }

    return node
  }

  return (
    <Flow rewriteNode={rewriteNode} sortFn={sortFn} onSubmit={onSubmit} flow={flow} />
  )
}

export default SignUpForm
