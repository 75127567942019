import React from 'react'

import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'

import { Navigation, AuthProvider } from './components'
import { store } from './store'

const App = () => {
  return (
    <Provider store={store}>
      <React.Fragment>
        <Helmet>
          <title>OpenCars - Перевірка авто за номером</title>
          <meta name='description' content='Перевірка транспорного засобу за номерами. Проста та зрозуміла інформація про транспотрний засіб. Найбільш повна інформація про транспортні засоби' />
          <meta name='theme-color' content='#000000' />
        </Helmet>
        <AuthProvider>
          <Navigation />
        </AuthProvider>
      </React.Fragment>
    </Provider>
  )
}

export default App
