import { UiNode } from '@ory/kratos-client'

import {
  isUiNodeInputAttributes, isUiNodeImageAttributes, isUiNodeScriptAttributes, isUiNodeTextAttributes, isUiNodeAnchorAttributes,
  FormDispatcher, ValueSetter
} from './helpers'

import { NodeImage } from './NodeImage'
import { NodeScript } from './NodeScript'
import { NodeText } from './NodeText'
import { NodeAnchor } from './NodeAnchor'
import { NodeInput } from './NodeInput'

interface Props {
  node: UiNode
  disabled: boolean
  value: any
  setValue: ValueSetter
  dispatchSubmit: FormDispatcher
}

export function Node({ node, value, setValue, disabled, dispatchSubmit }: Props) {
  if (isUiNodeImageAttributes(node.attributes)) {
    return <NodeImage node={node} attributes={node.attributes} />
  }

  if (isUiNodeScriptAttributes(node.attributes)) {
    return <NodeScript node={node} attributes={node.attributes} />
  }

  if (isUiNodeTextAttributes(node.attributes)) {
    return <NodeText node={node} attributes={node.attributes} />
  }

  if (isUiNodeAnchorAttributes(node.attributes)) {
    return <NodeAnchor attributes={node.attributes} />
  }

  if (isUiNodeInputAttributes(node.attributes)) {
    return (
      <NodeInput
        dispatchSubmit={dispatchSubmit}
        value={value}
        setValue={setValue}
        node={node}
        disabled={disabled}
        attributes={node.attributes}
      />
    )
  }

  return null
}
