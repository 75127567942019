import { Action } from './action'
import { Advertisement } from './advertisement'
import { Vin } from './vin'
import { WantedVehicleV2 } from './wanted_vehicle'

export type Report = {
  vehicles: VehicleReport[];
}

export type VehicleReport = {
  vin?: Vin;
  first_reg_date?: Date;
  brand: string;
  model: string;
  year: number;
  actions: Action[];
  advertisements?: Advertisement[];
  wanted?: WantedVehicleV2[];
}

export const reportTitle = (report?: Report) => {
  if (!report) {
    return 'Не знайдено'
  }

  if (report.vehicles.length === 1) {
    const vehicle = report!.vehicles[0]

    const prefix = `${vehicle.brand} ${vehicle.model} ${vehicle.year}`

    if (vehicle.vin && vehicle.vin.value) {
      return `${vehicle.vin.value} - ${prefix} - OpenCars`
    }

    return `${prefix} - OpenCars`
  }

  return 'OpenCars - Перевірка авто за номером'
}
