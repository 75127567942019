import { Report } from '../models'
import BaseAPI from './api'

const ReportAPI = BaseAPI.injectEndpoints({
  endpoints: (build) => ({
    getReportByNumber: build.query<Report, string>({
      query: (number: string) => ({
        url: `${process.env.REACT_APP_OPENCARS_PUBLIC_URL}/core.VehicleService/FindByNumber`,
        method: 'POST',
        body: {
          number: number
        }
      })
    }),
    getReportByVin: build.query<Report, string>({
      query: (vin: string) => ({
        url: `${process.env.REACT_APP_OPENCARS_PUBLIC_URL}/core.VehicleService/FindByVIN`,
        method: 'POST',
        body: {
          vin: vin
        }
      })
    })
  })
})

export const { useGetReportByNumberQuery, useGetReportByVinQuery } = ReportAPI
