import { FC } from 'react'
import { RowProps } from './Row.props'

import styles from './Row.module.css'

const Row: FC<RowProps> = ({ title, children, className, ...props }) => {
  if (!children || children === '') {
    return null
  }

  return (
    <div className={[styles.row, className].join(' ')} {...props}>
      { title && <strong>{title}</strong> }
      <span>{children}</span>
    </div>
  )
}

export default Row
