import { FC } from 'react'
import { upperFirst, lowerCase } from 'lodash'

import { toLocalDate, WantedVehicleV2 } from '../models'
import Row from './Row/Row'

type Props = {
  wanted: WantedVehicleV2
}

const WantedItemCard: FC<Props> = ({ wanted }) => {
  return (
    <div>
      <Row title='Марка'>{wanted.title || `${wanted.brand} ${wanted.model}`} </Row>
      <Row title='Номер'>{wanted.number}</Row>
      <Row title='Дата'>{toLocalDate(wanted.theft_date)}</Row>
      <Row title='Орган'>{upperFirst(lowerCase(wanted.ovd))}</Row>
      <Row title='VIN'>{wanted.body_number}</Row>
    </div>
  )
}

export default WantedItemCard
