import { useEffect, useContext } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import { SignInForm, AuthContext } from '../components'

const SignInPage = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const { isAuthenticated } = useContext(AuthContext)

  useEffect(() => {
    const checkSession = () => {
      if (isAuthenticated) {
        navigate('/profile')
      }
    }

    checkSession()
  }, [isAuthenticated])

  return (
    <div className='container'>
      <div className='right-view'>
        <h1>Увійти</h1>
        <div className='subheading'>
          <span>У вас ще не має облікового запису?</span>
          <Link className='link' to='/registration'>Зареєструватися</Link>
        </div>
        <SignInForm flowId={searchParams.get('flow')} />
      </div>
    </div>
  )
}

export default SignInPage
