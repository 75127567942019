import { FC, useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { AuthContext } from '..'
import { KratosAPI } from '../../api'
import LinearProgress from '../LinearProgress/LinearProgress'

type NavigationBarItemProps = {
  title: string
  href?: string
  to: string
}

interface Props {
  items: NavigationBarItemProps[]
}

const NavigationBar: FC<Props> = ({ items }) => {
  const isPending = useSelector((state: any) => Object.values(state.api.queries).some((query: any) => query.status === 'pending'))

  const navigate = useNavigate()

  const { logoutToken, isAuthenticated, setSession } = useContext(AuthContext)

  const LogOut = () => KratosAPI.submitSelfServiceLogoutFlow(logoutToken)
    .then(() => setSession(null))
    .then(() => navigate('login'))

  console.log(isAuthenticated, LogOut)
  const [wrapperClasses] = useState(['nav__wrapper'])
  const [wrapperClassesDropdown, setWrapperClassesDropdown] = useState(['nav__wrapper_dropdown'])

  const onToggleClick = () => {
    if (wrapperClassesDropdown.includes('active')) {
      setWrapperClassesDropdown(['nav__wrapper_dropdown'])
    } else {
      setWrapperClassesDropdown(['nav__wrapper_dropdown', 'active'])
    }
    console.log(wrapperClassesDropdown)
  }

  return (
    <header className='site-header'>
      <div className='site-header__wrapper'>
        <div className='site-header__middle'>
          <nav className='nav'>
            <ul className={wrapperClasses.join(' ')}>
              {
                items.map((item, idx) => {
                  return (
                    <li key={idx} className='nav__item'>
                      {
                        item.href ? <a href={item.href}>{item.title}</a> : <Link to={item.to}>{item.title}</Link>
                      }
                    </li>
                  )
                })
              }
            </ul>
          </nav>
        </div>

        <div className='site-header__end'>
          {isAuthenticated
            ? <div className='site-header__end__item'>
              <button onClick={LogOut}>Вийти</button>
            </div>
            : <div className='site-header__end__item'>
              <Link to='login'>Увійти</Link>
            </div>
          }

          <button className='nav__toggle' type="button" onClick={onToggleClick}>
            <svg viewBox="0 0 120 80" width="24" height="24" fill="#FFFFFF">
              <rect y="0" width="120" height="10"></rect>
              <rect y="40" width="120" height="10"></rect>
              <rect y="80" width="120" height="10"></rect>
            </svg>
          </button>
        </div>
      </div>

      <div className='site-header__dropdown'>
        <nav className='nav-dropdown'>
          <ul className={wrapperClassesDropdown.join(' ')}>
            {
              items.map((item, idx) => {
                return (
                  <li key={idx} className='nav-dropdown__item'>
                    {
                      item.href ? <a href={item.href}>{item.title}</a> : <Link to={item.to}>{item.title}</Link>
                    }
                  </li>
                )
              })
            }
          </ul>
        </nav>
      </div>
      {
        isPending && <LinearProgress/>
      }
    </header>
  )
}

export default NavigationBar
