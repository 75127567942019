import { useEffect, useContext } from 'react'

import { RecoveryForm, AuthContext } from '../components'

import { useNavigate, useSearchParams } from 'react-router-dom'

const RecoveryPage = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const { isAuthenticated } = useContext(AuthContext)

  useEffect(() => {
    const checkSession = () => {
      if (isAuthenticated) {
        navigate('/profile')
      }
    }

    checkSession()
  }, [isAuthenticated])

  return (
    <div className='container'>
      <div className='right-view'>
        <h1>Ви забули пароль?</h1>
        <div className='subheading'>
          Введіть електронну адресу, пов’язану з вашим обліковим записом, і ми надішлемо вам інструкції щодо відновлення пароля
        </div>
        <RecoveryForm flowId={searchParams.get('flow')} />
      </div>
    </div>
  )
}

export default RecoveryPage
