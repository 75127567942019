import { useState, useEffect, FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { SelfServiceRecoveryFlow, SubmitSelfServiceRecoveryFlowBody, UiNode } from '@ory/kratos-client'
import { AxiosError } from 'axios'

import { KratosAPI } from '../api'
import { handleFlowError } from '../ui/errors'
import { Flow } from '../ui/Flow'

type RecoveryPageProps = {
  flowId: string | null;
}

const RecoveryPage: FC<RecoveryPageProps> = ({ flowId }) => {
  const [flow, setFlow] = useState<SelfServiceRecoveryFlow>()

  const navigate = useNavigate()

  const onSubmit = (data: SubmitSelfServiceRecoveryFlowBody) => {
    return KratosAPI.submitSelfServiceRecoveryFlow(flow!.id, data).then(({ data }) => {
      console.log(data)
    }).catch(
      handleFlowError(navigate, 'recovery', setFlow)
    ).catch((err: AxiosError) => {
      if (err.response?.status === 400) {
        setFlow(err.response.data as SelfServiceRecoveryFlow)
      }
    })
  }

  useEffect(() => {
    if (!flowId) {
      KratosAPI.initializeSelfServiceRecoveryFlowForBrowsers().then(({ data }) => setFlow(data))
    } else {
      KratosAPI.getSelfServiceRecoveryFlow(flowId).then(({ data }) => setFlow(data))
    }
  }, [setFlow])

  const sortFn = (a: UiNode, b: UiNode) => {
    if (b.group === 'oidc') { return -1 }

    return 0
  }

  const rewriteNode = (node: UiNode) => {
    if (!node.meta.label) {
      return node
    }

    switch (node.meta.label?.id) {
      case 1070007:
        node.meta.label.text = 'Email'
        break
      case 1070005:
        node.meta.label.text = 'Зберегти'
        break
    }

    return node
  }

  return (
    <Flow rewriteNode={rewriteNode} sortFn={sortFn} onSubmit={onSubmit} flow={flow} />
  )
}

export default RecoveryPage
