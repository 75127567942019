import { useState, useEffect, useContext, FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { SelfServiceLoginFlow, SubmitSelfServiceLoginFlowBody, UiNode } from '@ory/kratos-client'
import { AxiosError } from 'axios'

import { KratosAPI } from '../api'
import { AuthContext } from '../components'
import { Flow } from '../ui/Flow'
import { handleFlowError } from '../ui/errors'

type SignInFormProps = {
  flowId: string | null
}

const SignInForm: FC<SignInFormProps> = ({ flowId }) => {
  const { setSession } = useContext(AuthContext)

  const [flow, setFlow] = useState<SelfServiceLoginFlow>()

  const navigate = useNavigate()

  useEffect(() => {
    if (!flowId) {
      KratosAPI.initializeSelfServiceLoginFlowForBrowsers().then(({ data }) => setFlow(data))
    } else {
      KratosAPI.getSelfServiceLoginFlow(flowId).then(({ data }) => setFlow(data))
    }
  }, [setFlow])

  const onSubmit = (data: SubmitSelfServiceLoginFlowBody) => {
    return KratosAPI.submitSelfServiceLoginFlow(flow!.id, data).then((resp) => {
      KratosAPI.createSelfServiceLogoutFlowUrlForBrowsers().then(({ data }) => {
        setSession({ session: resp.data.session, isAuthenticated: true, logoutToken: data.logout_token })
        navigate('/profile')
      })
    }).catch(
      handleFlowError(navigate, 'registration', setFlow)
    ).catch((err: AxiosError) => {
      if (err.response?.status === 400) {
        setFlow(err.response.data as SelfServiceLoginFlow)
      }
    })
  }

  const sortFn = (a: UiNode, b: UiNode) => {
    if (b.group === 'oidc') { return -1 }

    return 0
  }

  const rewriteNode = (node: UiNode) => {
    if (!node.meta.label) {
      return node
    }

    switch (node.meta.label?.id) {
      case 1070004:
        node.meta.label.text = 'Email'
        break
      case 1070001:
        node.meta.label.text = 'Пароль'
        break
      case 1010001:
        node.meta.label.text = 'Увійти'
        break
      case 1010002:
        node.meta.label.text = 'Увійти через Google'
        break
    }

    return node
  }

  return (
    <Flow rewriteNode={rewriteNode} sortFn={sortFn} onSubmit={onSubmit} flow={flow} />
  )
}

export default SignInForm
