import React from 'react'
import { Helmet } from 'react-helmet'

const NotFoundView = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Сторінка не знайдена</title>
        <meta name='theme-color' content='#000000' />
      </Helmet>

      <div className='main-title-wrapper'>
        <h1>404</h1>
        <h2>Сторінка не знайдена</h2>
        <p>Трапилось щось страшне, або сторінки вже не існує
          <span role="img" aria-label="astonished">😲</span>
        </p>
      </div>
    </React.Fragment>
  )
}

export default NotFoundView
