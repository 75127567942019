import { FC, useState } from 'react'

import styles from './CopyToClipboard.module.css'
import { CopyToClipboardProps } from './CopyToClipboard.props'

const CopyToClipboard: FC<CopyToClipboardProps> = ({ text, textClassName }) => {
  const [isCopied, setIsCopied] = useState<boolean>(false)

  const copyToClipboard = async () => {
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 1000)

    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  return (
    <div className={styles.container}>
      <div className={textClassName ? [styles.text, textClassName].join(' ') : styles.text}>
        <code className={textClassName}>{text}</code>
      </div>
      <button onClick={copyToClipboard}>
        {
          isCopied
            ? <svg className={styles.logo} xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 0 32 32" width="32">
              <path d="M0 0h32v32H0z" fill="none" />
              <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" fill="green" />
            </svg>
            : <svg className={styles.icon} xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 0 32 32" width="32">
              <path d="M6.5 13.5V3.5C6.5 3.22386 6.72386 3 7 3H17C17.2761 3 17.5 3.22386 17.5 3.5V13.5C17.5 13.7761 17.2761 14 17 14H7C6.72386 14 6.5 13.7761 6.5 13.5Z" fill="none" />
              <path d="M2.5 17.5V7.5C2.5 7.22386 2.72386 7 3 7H13C13.2761 7 13.5 7.22386 13.5 7.5V17.5C13.5 17.7761 13.2761 18 13 18H3C2.72386 18 2.5 17.7761 2.5 17.5Z" fill="white" />
            </svg>
        }
      </button>
    </div>
  )
}

export default CopyToClipboard
