import { useEffect, useContext } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { SettingsForm, AuthContext } from '../components'

const SettingsPage = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const { isAuthenticated } = useContext(AuthContext)

  const checkSession = () => {
    if (!isAuthenticated) {
      navigate('login')
    }
  }

  useEffect(() => {
    checkSession()
  }, [isAuthenticated])

  if (!isAuthenticated) {
    return null
  }

  return (
    <div className='container'>
      <div className='right-view'>
        <h1>Налаштування</h1>
        <SettingsForm flowId={searchParams.get('flow')} />
      </div>
    </div>
  )
}

export default SettingsPage
