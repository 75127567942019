import { FC } from 'react'
import CopyToClipboard from '../CopyToClipboard/CopyToClipboard'

import { toDateFromStr } from '../../models'

import styles from './TokenCard.module.css'
import { useDeleteTokenMutation } from '../../store/tokens_api'
import { TokenCardProps } from './SearchBox.props'

const TokenCard: FC<TokenCardProps> = ({ token }) => {
  const [deleteToken] = useDeleteTokenMutation()
  // const [resetToken] = useResetTokenMutation()

  const onDelete = () => {
    return () => {
      deleteToken(token.id)
    }
  }

  // const onReset = () => {
  //   return () => {
  //     // Open model.
  //     resetToken(token.id)
  //   }
  // }

  return (
    <div className={styles.container}>
      <div className={styles.padding}>
        <div className={styles.head}>
          <div className={styles.headStatus}>
            {
              token.enabled
                ? <div className={[styles.status, styles.enabled].join(' ')}>
                  Активний
                </div>
                : <div className={[styles.status, styles.disabled].join(' ')}>
                  Неактивний
                </div>
            }
          </div>

          <div className={styles.headIcons}>
            <button className='icon-btn' onClick={onDelete()}>
              <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M12.1989 18H7.86556C6.06556 18 4.53223 16.3158 4.53223 14.2105V7.47368C4.53223 7.05263 4.79889 6.77193 5.19889 6.77193C5.59889 6.77193 5.86556 7.05263 5.86556 7.47368V14.2105C5.86556 15.4737 6.73223 16.5965 7.86556 16.5965H12.1989C13.2656 16.5965 14.1989 15.5439 14.1989 14.2105V7.47368C14.1989 7.05263 14.4656 6.77193 14.8656 6.77193C15.2656 6.77193 15.5322 7.05263 15.5322 7.47368V14.2105C15.4656 16.3158 13.9989 18 12.1989 18Z' fill='#63708A' />
                <path d='M16.3333 4.66666H12.8C12.6 3.19298 11.4667 2 10 2C8.53333 2 7.4 3.19298 7.2 4.66666H3.66667C3.26667 4.66666 3 4.94736 3 5.36842C3 5.78947 3.26667 6.07017 3.66667 6.07017H16.3333C16.7333 6.07017 17 5.78947 17 5.36842C17 4.94736 16.7333 4.66666 16.3333 4.66666ZM10 3.47368C10.7333 3.47368 11.3333 4.03508 11.4667 4.73684H8.53333C8.66667 3.96491 9.26667 3.47368 10 3.47368Z' fill='#63708A' />
              </svg>
            </button>
            {/* <button className='icon-btn' onClick={onReset()}>
              <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M16.3813 12.3955L16.1973 12.317C15.8695 13.0849 15.4014 13.7633 14.7905 14.368C13.5872 15.5286 11.9771 16.1776 10.255 16.1776C8.53321 16.1776 6.93815 15.5288 5.71763 14.3662L5.71762 14.3662C4.49987 13.2065 3.84487 11.6566 3.84487 9.99995C3.84487 8.34532 4.51441 6.80971 5.71879 5.63235C6.92223 4.47142 8.53252 3.82225 10.2548 3.82225C11.9764 3.82225 13.5713 4.47095 14.7917 5.63324C14.9408 5.7771 15.0772 5.91941 15.2017 6.06664H12.8345C12.5535 6.06664 12.3121 6.28948 12.3121 6.57772C12.3121 6.86598 12.5535 7.0888 12.8345 7.0888H16.2201C16.5011 7.0888 16.7425 6.86596 16.7425 6.57772L16.7424 3.31107C16.7424 3.02282 16.501 2.8 16.22 2.8C15.939 2.8 15.6976 3.02284 15.6976 3.31108V5.07288C15.6412 5.01336 15.5825 4.95358 15.5204 4.89376L15.5196 4.89299C14.1118 3.55057 12.2404 2.8 10.2549 2.8C8.26922 2.8 6.39745 3.55068 4.98927 4.90944C3.5808 6.26832 2.8 8.07768 2.8 10C2.8 11.9223 3.5808 13.7316 4.98926 15.0905C6.39739 16.4493 8.26922 17.2 10.2549 17.2C12.2405 17.2 14.1123 16.4493 15.5205 15.0906C16.2331 14.403 16.7804 13.6029 17.1617 12.7072L17.1624 12.7055C17.2415 12.515 17.1915 12.3021 17.0568 12.1611L17.079 12.1174L16.8723 12.0378C16.6166 11.9391 16.3052 12.0565 16.1965 12.3189L16.3813 12.3955ZM16.3813 12.3955C16.0426 13.1888 15.5591 13.8889 14.9303 14.511M16.3813 12.3955C16.4168 12.3097 16.4916 12.2475 16.5786 12.2195L14.9303 14.511M14.9303 14.511C13.6889 15.7088 12.0284 16.3776 10.255 16.3776C8.48156 16.3776 6.83719 15.7088 5.57969 14.511C4.32207 13.3133 3.64487 11.711 3.64487 9.99995C3.64487 8.28885 4.33805 6.70219 5.57946 5.48887L14.9303' stroke='#63708A' />
              </svg>
            </button> */}
          </div>
        </div>

        <div className={styles.row}>
          <strong>Назва</strong>
          <div>{token.name}</div>
        </div>

        <div className={styles.row}>
          <strong>Створений </strong>
          <span>{toDateFromStr(token.created_at)}</span>
        </div>

        {
          token.secret && <CopyToClipboard text={token!.secret!} />
        }
      </div>
    </div>
  )
}

export default TokenCard
