import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { SearchBox, Footer } from '../../components'
import { toDateFromStr, WantedVehicle } from '../../models'

import styles from './HomePage.module.css'
import { useGetWantedQuery } from '../../store/wanted_api'

const chips = [
  {
    label: 'Номерний знак',
    path: '/number/',
    disabled: false,
    placeholder: 'АА9359РС',
    length: 8
  },
  {
    label: 'VIN',
    path: '/vin/',
    disabled: false,
    placeholder: '5YJXCCE40GF010543',
    length: 17
  },
  {
    label: 'Номер свідотства',
    path: '/code/',
    disabled: true,
    placeholder: 'СХН484154',
    length: 9
  }
]

const HomePage = () => {
  const { data = [], isLoading } = useGetWantedQuery(36)

  const reportURL = (vehicle: WantedVehicle) => {
    if (vehicle.number) {
      return '/number/' + vehicle.number
    } else if (vehicle.body_number) {
      return '/vin/' + vehicle.body_number
    } else if (vehicle.chassis_number) {
      return '/vin/' + vehicle.chassis_number
    }
    return '/'
  }

  if (isLoading) {
    return (
      <div className={styles.contentWrapper}>
        <div className={styles.pageTitle}>
          <h2>Перевірка транспорного засобу</h2>
        </div>

        <SearchBox chips={chips} />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className={styles.contentWrapper}>
        <div className={styles.pageTitle}>
          <h2>Перевірка транспорного засобу</h2>
        </div>

        <SearchBox chips={chips} />

        <div className={styles.gridContainer}>
          {
            data.map((vehicle, index) => {
              return (
                <div key={index} className={styles.vehicleWrapper}>
                  <div className={styles.vehicle}>
                    <RouterLink className={styles.vehicleLink} to={reportURL(vehicle)}>
                      <div className={styles.brand}>
                        <strong>{vehicle.brand}</strong>
                      </div>

                      <div className={styles.row}>
                        <strong className={styles.rowTitle}>Номер: </strong>
                        <span>{vehicle.number || 'Heвідомий'}</span>
                      </div>

                      <div className={styles.row}>
                        <strong className={styles.rowTitle}>Викрадено: </strong>
                        <span>{toDateFromStr(vehicle.theft_date)}</span>
                      </div>
                    </RouterLink>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default HomePage
