import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { TokenCard, CreateTokenModal, AuthContext } from '../../components'

import { useGetTokensQuery } from '../../store/tokens_api'

const ProfilePage = () => {
  const navigate = useNavigate()

  const { isAuthenticated } = useContext(AuthContext)

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('login')
    }
  }, [isAuthenticated])

  if (!isAuthenticated) {
    return null
  }

  const { data = [], isLoading } = useGetTokensQuery(100)

  if (isLoading) {
    return <h1>Идет загрузка...</h1>
  }

  return (
    <div className='list-of-tokens'>
      <CreateTokenModal open={open} onClose={() => setOpen(false)} />

      <div className='flex-center'>
        <div className='tokens-heading'>
          <h2>Ваші токени</h2>
          <div>
            <button onClick={() => setOpen(true)} className='button primary-button'>Згенерувати токен</button>
          </div>
        </div>
        <div className='tokens-wrapper'>
          <div className='tokens-list'>
            {
              data.map((item, idx) => <TokenCard key={idx} token={item} />)
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfilePage
