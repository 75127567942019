import { getNodeLabel, NodeInputProps } from './helpers'

export function NodeInputPassword(props: NodeInputProps) {
  const { node, attributes, value = '', setValue, disabled } = props

  // Some attributes have dynamic JavaScript - this is for example required for WebAuthn.
  const onClick = () => {
    // This section is only used for WebAuthn. The script is loaded via a <script> node
    // and the functions are available on the global window level. Unfortunately, there
    // is currently no better way than executing eval / function here at this moment.
    if (attributes.onclick) {
      // eslint-disable-next-line no-new-func
      const run = new Function(attributes.onclick)
      run()
    }
  }

  // Render a generic text input field.
  return (
    <div className='form-control'>
      <label htmlFor={attributes.name}>{getNodeLabel(node)}</label>
      <input type={attributes.type} onClick={onClick} className={'input-success'}
        onChange={(e) => { setValue(e.target.value) }}
        name={attributes.name}
        value={value}
        disabled={attributes.disabled || disabled}
      />
    </div>
  )
}
