import { FC } from 'react'
import { Row } from '..'

import { DecodedVINProps } from './DecodedVIN.props'

const DecodedVIN: FC<DecodedVINProps> = ({ vin, className }) => {
  if (!vin) {
    return null
  }

  return (
    <div className={className}>
      {
        vin && vin.vehicle && <>
          <Row style={{ color: '#3081F6' }}>{vin.value}</Row>

          <Row title='Країна'>{vin.vehicle.region}, {vin.vehicle.country}</Row>
          <Row title='Виробник'>{vin.vehicle.manufacturer}</Row>
          <Row title='Рік'>{vin.vehicle.year}</Row>
        </>
      }
    </div>
  )
}

export default DecodedVIN
