import { WantedVehicleList } from '../models'
import BaseAPI from './api'

const WantedAPI = BaseAPI.injectEndpoints({
  endpoints: (build) => ({
    getWanted: build.query<WantedVehicleList, number>({
      query: (count) => `${process.env.REACT_APP_OPENCARS_WANTED_API_URL}/api/v1/wanted/vehicles?limit=${count}`,
      providesTags: (result) =>
        result
          ? [...result.map(({ number, date }) => ({ type: 'WantedVehicle' as const, id: number + date })), { type: 'WantedVehicle', id: 'LIST' }]
          : [{ type: 'WantedVehicle', id: 'LIST' }]
    })
  })
})

export const { useGetWantedQuery } = WantedAPI
